import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/hayden/dev/fluidsolarthermal.com/web/src/components/layout-markdown.tsx";
import SEO from '../components/seo';
import BackgroundImage from 'gatsby-background-image';
import { graphql } from 'gatsby';
export const pageQuery = graphql`
  {
    longViewFluidSolarHouse: file(
      relativePath: {
        eq: "long-view-fluid-solar-house.jpg"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title={props.pageContext.frontmatter.title} mdxType="SEO" />
    <div className="escape-container">
  <BackgroundImage className="hero hero--commercial" Tag="section" fluid={props.data.longViewFluidSolarHouse.childImageSharp.fluid} mdxType="BackgroundImage">
    <div className="hero--overlay"></div>
    <div className="hero--box text-white">
      <h1>{props.pageContext.frontmatter.title}</h1>
      <h2>The better way to build your office, housing development, or educational space.</h2>
    </div>
  </BackgroundImage>
    </div>
    <p>{`At Fluid Solar, our R&D team are at the forefront of sustainable commercial development, having completed the world’s first off-grid, solar-thermal powered four storey office building—Fluid Solar House—in Elizabeth Vale, South Australia in 2017. The building’s heating, cooling and lighting system has now operated for more than 2 years completely off the grid, powered by rooftop solar thermal and solar PV panels.
With our patented solar thermal systems and experienced team of designers and engineers, we are well-placed to work with you on your sustainable solar thermal hybrid energy-powered commercial building or project. We can facilitate:`}</p>
    <ul>
      <li parentName="ul">{`Multi-residential developments (including caravan parks or similar)`}</li>
      <li parentName="ul">{`Small to medium sized office buildings`}</li>
      <li parentName="ul">{`Educational spaces`}</li>
      <li parentName="ul">{`Retail stores`}</li>
    </ul>
    <p>{`Explore our `}<a parentName="p" {...{
        "href": "https://fluidsolarthermal.com/projects/"
      }}>{`previous development projects`}</a>{`, or fill out the form below to express your interest in a commercial project and we will be in touch shortly.`}</p>
    <p><img parentName="p" {...{
        "src": "../../images/Light%20Avenue%20render%20with%20solar%20thermal%20panels.jpg",
        "alt": "Light avenue"
      }}></img></p>
    {
      /* TODO:
      Here create form specifically for commercial development applications, separate email address? Also include
      Name
      Email
      Type of project
      Project deadline
      Space for a brief message and description of their aims */
    }
    {
      /* 
      ![cutaway](../images/cutaway.jpg)
      Developing a commercial project powered by renewable energy presents a unique variety of challenges, especially for large-scale commercial and industrial properties.
      At Fluid Solar, we’re at the forefront of sustainable commercial development, having recently completed the world’s first off-grid, solar-thermal powered four storey office building – Fluid Solar House – in Elizabeth Vale, South Australia. With our knowledge of solar thermal systems, combined with our experienced team of builders and architects, we’re well placed to work with you on your green energy-powered commercial building, from project inception to completion.
      Our building and energy technology is ideally suited for small to medium density residential projects; tourism operators, healthcare providers; educational institutions; government; NGOs, and more.
      | Building Construction                           | Annual Energy Consumption | Typical Construction Costs |
      | ----------------------------------------------- | ------------------------- | -------------------------- |
      | Office built c. 1950                            | 400kWh/m²                 | \$2,500/m²                 |
      | Standard Office Building                        | 140kWh/m²                 | \$3,000/m²                 |
      | Well designed Modern Office                     | 75kWh/m²                  | \$4,000/m²                 |
      | A Green Star Building                           | 26kWh/m²                  | \$5,000/m²                 |
      | Fluid Solar Office Building                     | 0kWh/m²                   | \$2,500/m²                 |
      | Fluid Solar large scale residential Building \* | 0kWh/m²                   | \$1000/m²                  |
      ## **OUR COMMERCIAL SERVICES INCLUDE**
      ### Multi-Residential
      Our prefab homes are ideal for small to medium density multi-residential developments, and can be customised to suit your budget. We recently completed a suite of cabins for a Caravan Park in Gawler, South Australia, and are currently working on a series of two-storey town houses.
      ### Design and Supply
      Our complete custom design and supply solution provides solar-thermal technology for energy optimised commercial and industrial properties.
      ### Building Envelope Design
      We’ll design all aspects of the solar-thermal system from the energy monitoring receptors to the thermal banks and collectors arrays. Passive design features, as well as glazing design, are optimised and outlined.
      ### Energy Systems
      Our Solar thermal powered HVAC systems can be incorporated into building design for your commercial or industrial property.
      ### Retrofitting
      We can work with you on retrofitting your commercial or industrial property with a custom solar-thermal energy solution to significantly reduce electricity consumption.
      **Dependant on residential building construction method**
      To discuss your commercial project with us in depth, please do give us a CALL or EMAIL US and we’ll get back to you within 48 hours
      ---
      Contact us about your next project
      127 Haydown Rd, Elizabeth Vale SA 5112, Australia
      enquiries@fluidsolarthermal.com
      +618 8440 7788
      +61 414 450 215 */
    }


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      